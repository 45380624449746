import Cookies from "js-cookie";

import Dashboard from "layouts/dashboard";
import WaterSupply from "layouts/watersupply";
import Requests from "layouts/requests";
import Complaints from "layouts/complaints";
import Events from "layouts/events";
import Slider from "layouts/slider";
// import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
// import { DataContextProvider } from "context/complaintsData";

const RouteHandler = ({ routeName }) => {
  const myRoutes = {
    dashboard: Dashboard,
    waterSupply: WaterSupply,
    request: Requests,
    complaint: Complaints,
    event: Events,
    slider: Slider,
  };

  const SelectedRoute = myRoutes[routeName];
  return (
    // <DataContextProvider>
    <SelectedRoute />
    // </DataContextProvider>
  );
};

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: Cookies.get("token") ? <RouteHandler routeName="dashboard" /> : <SignIn />,
  },
  {
    type: "collapse",
    name: "Request",
    key: "Requests",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/request",
    component: Cookies.get("token") ? <RouteHandler routeName="request" /> : <SignIn />,
  },
  {
    type: "collapse",
    name: "Water Supply",
    key: "watersupply",
    icon: <Icon fontSize="small">local_drink</Icon>,
    route: "/watersupply",
    component: Cookies.get("token") ? <RouteHandler routeName="waterSupply" /> : <SignIn />,
  },
  {
    type: "collapse",
    name: "Complaints",
    key: "complaints",
    icon: <Icon fontSize="small">description</Icon>,
    route: "/complaints",
    component: Cookies.get("token") ? <RouteHandler routeName="complaint" /> : <SignIn />,
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">diversity_3</Icon>,
    route: "/events",
    component: Cookies.get("token") ? <RouteHandler routeName="event" /> : <SignIn />,
  },

  {
    type: "collapse",
    name: "Slider",
    key: "Slider",
    icon: <Icon fontSize="small">image</Icon>,
    route: "/slider",
    component: Cookies.get("token") ? <RouteHandler routeName="slider" /> : <SignIn />,
  },
  {
    type: "logout",
    name: "Sign Out",
    key: "sign-in",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse1",
    name: "Sign Out",
    key: "sign-up",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
