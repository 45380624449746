import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";

import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
// import {useDataContext} from '../../context/complaintsData'

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";

import ErrorSnackbar from "examples/Snackbar/ErrorSnackbar";
import traceAndThrow from "utils/Errors";

function Dashboard() {
  const [error, setError] = useState(false);
  const [errText, setErrText] = useState(false);
  const [complaint, setComplaint] = useState(0);
  const [request, setRequest] = useState(0);
  const [waterFlow] = useState(0);
  const [events, setEvents] = useState(0);

  // const  {complaintsData, setComplaintsData} = useDataContext()

  const getData = async () => {
    try {
      const myRes = await axios.get("https://api.rauhelpline.in/all-documents-counts/secure", {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      const { data } = await axios.get(
        "https://api.rauhelpline.in/get-all-user-complaints/secure?complaintStatus=pending",
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      );
      console.log("log41", data);
      setComplaint(myRes.data.body.complaintsCount);
      setEvents(myRes.data.body.eventCount);
      setRequest(myRes.data.body.requestCount);
    } catch (err) {
      setError(true);
      setErrText(traceAndThrow(err));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="description"
                  title="Complaints"
                  count={complaint}
                  route="/complaints"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="receipt_long"
                  title="request"
                  count={request}
                  route="/request"
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="local_drink"
                  title="Water Supply"
                  count={waterFlow}
                  route="/watersupply"
                  // percentage={{
                  //   color: "success",
                  //   amount: newWaterFlow,
                  //   label: "In this week",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="diversity_3"
                  title="Events"
                  count={events}
                  route="/events"
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={12}>
                <Projects />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </DashboardLayout>

      {error && (
        <ErrorSnackbar
          text={errText}
          handleClose={() => {
            setError(false);
          }}
        />
      )}
    </>
  );
}

export default Dashboard;
